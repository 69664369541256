<template>
    <div class="pa-3">
        <div class="pa-3 white rounded">
            <v-row>
                <v-col>
                    <v-text-field :value="orderNum" @input="setOrderNum" placeholder="Order number" flat hide-details solo-inverted 
                        v-mask="'XXX-#########'" @focus="setView('o')" @keydown.enter="loadOrder"></v-text-field>
                </v-col>
                <v-col cols="1">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn large icon v-bind="attrs" v-on="on" @click="setView('i')"><v-icon>mdi-table</v-icon></v-btn>
                        </template>
                        <span>View item list</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn large icon v-bind="attrs" v-on="on" @click="setView('p')"><v-icon>mdi-printer-outline</v-icon></v-btn>
                        </template>
                        <span>Print tickets</span>
                    </v-tooltip>
                    
                </v-col>
            </v-row>
        </div>

        <v-card flat class="mt-3" :loading="loadingView">
            <!-- ORDER -->
            <v-card-text v-show="view == 'o'" :class="order != null && order.changes.length > 0 ? 'modified rounded' : 'rounded'">
                <v-row v-if="order != null">
                    <v-col cols="12" v-if="order.statusId == 24">
                        <v-alert color="error" text border="left" class="font-weight-bold">This order has been cancelled</v-alert>
                    </v-col>
                    <v-col cols="4">
                        <div class="subtitle-1 mb-3">Order Information</div>

                        <div class="text-left">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip :color="orderStatusColor(order.status)" label class="white--text mb-3 headline" 
                                        v-bind="attrs" v-on="on">
                                        {{ order.foreignId }}
                                    </v-chip>
                                </template>
                                <span>{{ order.status }}</span>
                            </v-tooltip>
                        </div>

                        <v-simple-table>
                            <tbody>
                                <tr>
                                    <th>Status</th>
                                    <td>{{ order.status }}</td>
                                </tr>
                                <tr>
                                    <th>Pickup location</th>
                                    <td>[{{ order.pickupLocation.wintuxID}}] {{ order.pickupLocation.name }}  |  {{ order.pickupLocation.phone }}</td>
                                </tr>
                                <tr>
                                    <th>Book date</th>
                                    <td>{{ formatDate( order.orderDate, 'YYYY-MM-DD @ hh:mm a' ) }}</td>
                                </tr>
                                <tr>
                                    <th>Pickup date</th>
                                    <td>{{ formatDate( order.wantDate, 'YYYY-MM-DD @ hh:mm a' ) }}</td>
                                </tr>
                                <tr>
                                    <th>Use date</th>
                                    <td>{{ order.useDate }}</td>
                                </tr>
                                <tr>
                                    <th>Return date</th>
                                    <td>{{ order.returnDate }}</td>
                                </tr>
                                <tr>
                                    <th>Customer type</th>
                                    <td>{{ order.eventRole }}</td>
                                </tr>
                                <tr>
                                    <th>Event</th>
                                    <td>{{ order.eventId == '0' ? '' : order.eventId }}</td>
                                </tr>
                                <tr>
                                    <th>Clerk</th>
                                    <td>{{ order.employee }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>

                        <div class="subtitle-1 my-3">Comments</div>
                        <div>{{ order.comments == '' ? 'No comments available' : order.comments }}</div>
                    </v-col>

                    <v-col cols="4">
                        <div class="subtitle-1 mb-3">Customer Information</div>
                        <customer-info :customer="order.customer" :edit="false" :avatar="false" show-measurements />
                    </v-col>

                    <v-col cols="4">
                        <div class="subtitle-1 mb-3">Items</div>
                        <package-viewer :value="orderPackage" :items-assigned="order.assignedItems" :changes="order.changes" inspection itemsonly />
                    </v-col>
                </v-row>
            </v-card-text>

            <!-- ITEMS -->
            <v-card-text class="items" v-show="view == 'i'">
                <v-row class="mb-2 sticky">
                    <v-col>
                        <v-text-field :value="styleFilter" @input="setStyleFilter" placeholder="Style" flat hide-details solo-inverted clearable @keydown.enter="loadItems"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field :value="sizeFilter" @input="setSizeFilter" placeholder="Size" flat hide-details solo-inverted clearable @keydown.enter="loadItems"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field :value="barcodeFilter" @input="setBarcodeFilter" placeholder="Barcode" flat hide-details solo-inverted clearable @keydown.enter="loadItems"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field :value="serialFilter" @input="setSerialFilter" placeholder="Serial #" flat hide-details solo-inverted clearable @keydown.enter="loadItems"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-select :value="typeFilter" @input="setTypeFilter" :items="productTypes" placeholder="Item type" item-text="name" item-value="id" flat hide-details solo-inverted clearable @change="loadItems"></v-select>
                    </v-col>
                    <v-col>
                        <v-text-field :value="orderFilter" @input="setOrderFilter" placeholder="Order #" flat hide-details solo-inverted clearable 
                            v-mask="'###-#########'" @keydown.enter="loadItems"></v-text-field>
                    </v-col>
                </v-row>
                <v-data-table :headers="itemHeaders" :items="items" :footer-props="paginationOptions" item-key="id"
                    :options="pagination" :loading="loading">
                    <template v-slot:item.style="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    {{ item.style }}
                                </div>
                            </template>
                            <span>{{ item.name }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.available="{ item }">
                        <div :class="'dot ' + (item.available ? 'success' : 'error')"></div>
                    </template>
                </v-data-table>
            </v-card-text>

            <!-- PRINT -->
            <v-card-text class="print" v-show="view == 'p'">
                <div class="subtitle-1 mb-3">What to print</div>

                <v-row>
                    <v-col cols="6">
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Print un-printed tickets
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div class="grey--text text--darken-2">Print all un-printed tickets through</div>
                                    <v-menu v-model="printAllMenu" transition="scale-transition" offset-y max-width="290px" min-width="auto" :close-on-content-click="false">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field :value="printAllDate" label="Date" hint="YYYY-MM-DD format" persistent-hint 
                                                prepend-icon="mdi-calendar" v-bind="attrs" @input="setPrintAllDate" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker :value="printAllDate" no-title @input="setPrintAllDate($event); printAllMenu = false"></v-date-picker>
                                    </v-menu>

                                    <v-btn block depressed color="grey darken-4" class="white--text mt-3" :loading="confirming" @click="confirmPrint('through')">Continue</v-btn>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Print un-printed tickets for date range
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div class="grey--text text--darken-2">Print all un-printed tickets for date range</div>

                                    <v-row>
                                        <v-col cols="6">
                                            <v-menu v-model="printFromMenu" transition="scale-transition" offset-y max-width="290px" min-width="auto" :close-on-content-click="false">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field :value="printFromDate" label="From date" hint="YYYY-MM-DD format" persistent-hint 
                                                        prepend-icon="mdi-calendar" v-bind="attrs" @input="setPrintFromDate" v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker :value="printFromDate" no-title @input="setPrintFromDate($event); printFromMenu = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>

                                        <v-col cols="6">
                                            <v-menu v-model="printToMenu" transition="scale-transition" offset-y max-width="290px" min-width="auto" :close-on-content-click="false">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field :value="printToDate" label="To date" hint="YYYY-MM-DD format" persistent-hint 
                                                        prepend-icon="mdi-calendar" v-bind="attrs" @input="setPrintToDate" v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker :value="printToDate" no-title :min="printFromDate" @input="setPrintToDate($event); printToMenu = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>

                                    <v-btn block depressed color="grey darken-4" class="white--text mt-3" :loading="confirming" @click="confirmPrint('range')">Continue</v-btn>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Print transaction
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div class="grey--text text--darken-2">Print ticket for a single order</div>

                                    <v-text-field :value="printOrder" label="Order number" @input="setPrintOrder" outlined solo flat 
                                        v-mask="'###-#########'" @keypress.enter="print('single')"></v-text-field>

                                    <v-btn block depressed color="grey darken-4" class="white--text mt-3" :loading="printing" @click="print('single')">Print</v-btn>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Reprint batch
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div class="grey--text text--darken-2">Reprint a whole print batch. The status or the transactions won't be changed.</div>
                                    <v-text-field :value="reprintBatch" placeholder="Batch number" @input="setReprintBatch" outlined solo flat v-mask="'##########'" @keypress.enter="confirmPrint('reprintBatch')"></v-text-field>

                                    <v-btn block depressed color="grey darken-4" class="white--text mt-3" :loading="confirming" @click="confirmPrint('reprintBatch')">Continue</v-btn>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Reprint transaction
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div class="grey--text text--darken-2">The status of the transaction won't be changed</div>

                                    <v-text-field :value="reprintOrder" label="Order number" @input="setReprintOrder" outlined solo flat 
                                        v-mask="'###-#########'" @keypress.enter="print('reprintOrder')"></v-text-field>

                                    <v-btn block depressed color="grey darken-4" class="white--text mt-3" :loading="printing" @click="print('reprintOrder')">Print</v-btn>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                    <v-col cols="6">
                        <div class="grey--text text--darken-2">Print batch list</div>

                        <v-row>
                            <v-col cols="6" offset="6">
                                <v-text-field v-model="filter" append-icon="mdi-magnify" label="Filter" single-line hide-details clearable></v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table :headers="batchHeaders" :items="batches" item-key="id" 
                            :options="batchPagination" :search="filter">
                            <template v-slot:item.dateCreated="{ item }">
                                {{ formatDate( item.dateCreated, 'YYYY-MM-DD @ hh:mm a' ) }}
                            </template>
                            <template v-slot:item.totalOrders="{ item }">
                                {{ item.orders.length }}
                            </template>
                            <template v-slot:item.print="{ item }">
                                <v-btn icon @click="() => {setReprintBatch(item.id); confirmPrint('reprintBatch');}">
                                    <v-icon>mdi-printer-outline</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog v-model="confirmationDialog" width="400" :persistent="printing" @keydown.enter="print()">
            <v-card>
                <v-card-title>Print confirmation</v-card-title>
                <v-card-text>
                    <div v-html="batchInfo"></div>
                </v-card-text>
                <v-card-actions>
                    <v-btn block depressed color="grey darken-4" class="white--text" :disabled="!isReadyToPrint" 
                        :loading="printing" @click="print()">PRINT</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { _st } from '@/softech';
import PackageViewer from '@/components/PackageViewer.vue';
import CustomerInfo from '@/components/CustomerInfo.vue';
import { mask } from 'vue-the-mask';
import moment from 'moment';
import { bus } from '@/main.js';

export default {
    data() {
        return {
            itemHeaders                 : [
                { text: 'Style', value: 'style', sortable: true },
                { text: 'Size', value: 'size', sortable: true },
                { text: 'Barcode', value: 'barcode', sortable: true },
                { text: 'Serial #', value: 'id', sortable: true },
                { text: 'Order #', value: 'orderNum', sortable: true },
                { text: 'Cur. location', value: 'currentLocation', sortable: true },
                { text: 'Available', value: 'available', sortable: true },
                { text: 'Status', value: 'status', sortable: true },
                { text: 'Date out', value: 'dateOut', sortable: true },
                { text: 'Due back', value: 'dateDueBack', sortable: true },
                { text: 'Date returned', value: 'lastReturnDate', sortable: true },
                { text: 'Turns', value: 'turns', sortable: true },
                { text: 'Transfer code', value: 'transferCode.code', sortable: true },
                // { text: 'Prev. location', value: 'prevLocation', sortable: true },
                { text: 'Date added', value: 'dateCreated', sortable: true },
                { text: 'Date inventoried', value: 'dateInventoried', sortable: true },
                { text: 'Cl. bin', value: 'cleaningBin', sortable: true },
                { text: 'Altered', value: 'altered', sortable: true },
                // { text: 'Turns YTD', value: 'turnsYTD', sortable: true },
                // { text: 'Last inspector', value: 'lastInspectorEmployeeId', sortable: true },
            ],
            pagination                  : {
                sortBy: ['style']
            },
            paginationOptions           : { itemsPerPageOptions: [ 50, 75, 100, -1 ] },

            batchHeaders                : [
                { text: 'Num', value: 'id', sortable: true },
                { text: 'Created', value: 'dateCreated', sortable: true },
                { text: 'Employee', value: 'employee', sortable: true },
                { text: 'Orders', value: 'totalOrders', sortable: true, align: 'right' },
                { text: 'Print', value: 'print', sortable: false }
            ],
            batchPagination             : {
                sortBy: ['dateCreated'],
                sortDesc: [true]
            },

            loading                     : false,
            loadingView                 : false,

            batchInfo                   : '',
            printAllMenu                : false,
            printFromMenu               : false,
            printToMenu                 : false,

            confirmationDialog          : false,
            confirming                  : false,

            action                      : '',
            batchQty                    : 0,
            printing                    : false,
            filter                      : ''
        }
    },
    computed: {
        ...mapGetters({
            productTypes    : 'productType/items',
            
            view            : 'stations/processing/view',
            items           : 'stations/processing/items',
            styleFilter     : 'stations/processing/styleFilter',
            sizeFilter      : 'stations/processing/sizeFilter',
            barcodeFilter   : 'stations/processing/barcodeFilter',
            serialFilter    : 'stations/processing/serialFilter',
            typeFilter      : 'stations/processing/typeFilter',
            orderFilter     : 'stations/processing/orderFilter',
            batches         : 'stations/processing/batches',

            orderNum        : 'stations/processing/orderNum',
            order           : 'stations/processing/order',

            printAllDate    : 'stations/processing/printAllDate',
            printFromDate   : 'stations/processing/printFromDate',
            printToDate     : 'stations/processing/printToDate',
            printOrder      : 'stations/processing/printOrder',
            reprintOrder    : 'stations/processing/reprintOrder',
            reprintBatch    : 'stations/processing/reprintBatch'
        }),
        orderPackage() {
            if( this.order == null )
                return {};

            let m = this.order.measurements;
            let items = [];
            this.order.items.forEach(i => {
                let size = '';
                if( i.categoryId == 'C' )       size = `${m.coatSize}${m.coatLength} - ${m.coatSleeve}`;
                else if( i.categoryId == 'P' )  size = `${m.pantWaist}${m.pantType} - ${m.pantOutseam}`;
                else if( i.categoryId == 'S' )  size = `${m.shirtSize}`;
                else if( i.categoryId == 'N' )  size = `${m.tieSize}`;
                else if( i.categoryId == 'V' )  size = `${m.vestSize}`;
                else if( i.categoryId == 'SH' ) size = `${m.shoeSize}${m.shoeType}`;
                else if( i.categoryId == 'CB' ) size = `${m.cummerbundSize}`;
                else                            size = '';

                items.push({
                    ...i,
                    size
                });
            });

            return {
                items: items,
            };
        },
        isReadyToPrint() {
            return !_st.isNUE( this.action ) && this.batchQty > 0;
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            loadProductTypes    : 'productType/load',

            setView             : 'stations/processing/setView',
            refreshItems        : 'stations/processing/loadItems',
            getBatches          : 'stations/processing/loadBatches',
            setStyleFilter      : 'stations/processing/setStyleFilter',
            setSizeFilter       : 'stations/processing/setSizeFilter',
            setBarcodeFilter    : 'stations/processing/setBarcodeFilter',
            setSerialFilter     : 'stations/processing/setSerialFilter',
            setTypeFilter       : 'stations/processing/setTypeFilter',
            setOrderFilter      : 'stations/processing/setOrderFilter',
            
            setOrderNum         : 'stations/processing/setOrderNum',
            getOrder            : 'stations/processing/loadOrder',

            setPrintAllDate     : 'stations/processing/setPrintAllDate',
            setPrintFromDate    : 'stations/processing/setPrintFromDate',
            setPrintToDate      : 'stations/processing/setPrintToDate',
            setPrintOrder       : 'stations/processing/setPrintOrder',
            setReprintOrder     : 'stations/processing/setReprintOrder',
            setReprintBatch     : 'stations/processing/setReprintBatch',

            getConfirmation     : 'stations/processing/confirmBatch',
            printBatch          : 'stations/processing/printBatch',
        }),
        async init() {
            try {
                if( this.printAllDate == null )
                    this.setPrintAllDate( this.formatDate( new Date() ) );

                await this.loadProductTypes();
                await this.getBatches();

                if( !_st.isNUE( this.$route.query?.orderId ) ) {
                    this.setOrderNum( this.$route.query.orderId );
                    this.setView('o');
                    this.loadOrder();
                }

                bus.$on('loadOrder', ( orderId ) => {
                    this.setOrderNum( orderId );
                    this.setView('o');
                    this.loadOrder();
                });
            }
            catch(error) {
                this.mxShowMessage( error, 'error' );
            }
        },
        async loadItems() {
            try {
                this.loading = true;
                await this.refreshItems();
                this.loading = false;
            }
            catch(error) {
                this.loading = false;
            }
            finally {
                this.setBarcodeFilter('');
            }
        },
        async loadOrder() {
            try {
                this.loading = true;
                await this.getOrder();
                this.loading = false;
            }
            catch(error) {
                this.loading = false;
            }
            finally {
                this.setOrderNum('');
            }
        },
        async confirmPrint( type ) {
            try {
                this.action = type;
                this.confirming = true;
                let total = await this.getConfirmation( type );
                this.confirming = false;

                if( total == 0 )
                    this.batchInfo = 'There are no orders to print.';
                else
                    this.batchInfo = `This will print <strong>${this.numberWithCommas( total )}</strong> tickets. Are you sure you want to continue?`;

                this.batchQty = total;
                this.confirmationDialog = true;
            }
            catch(error) {
                this.confirming = false;
            }
        },
        async print( type ) {
            try {
                this.printing = true;
                await this.printBatch( type ?? this.action );
                this.confirmationDialog = false;
                this.printing = false;

                await this.getBatches();
            }
            catch(error) {
                this.printing = false;
            }
        },
        formatDate( date, f = 'YYYY-MM-DD' ) {
            return moment( date ).format( f );
        },
    },
    components: { PackageViewer, CustomerInfo },
    directives: { mask }
}
</script>

<style scoped>
.dot {
    border-radius: 50%;
    width: 14px;
    height: 14px;
    margin: 0 auto;
}
.items .sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 60px;
    background-color: #fff;
}
.modified {
    border: 3px solid #FF5722;
}
</style>